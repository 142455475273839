import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { ENV_URL } from "../../constant";

const Oddeven = () =>{

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [list, setList] = useState([]);

    useEffect(() => {
        fetch(ENV_URL+"/oddevencount/",{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
        })
          .then(res => res.json())
          .then(
            (result) => {
              setIsLoaded(true);
              setList(result.combos);
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              setIsLoaded(true);
              setError(error);
            }
          )
      }, [])

    return(
        <div className="ll-box mid bottom-margin">
            <header>ODD EVEN LEVEL</header>
            <div className="section">
                <Grid className="ll-odd-even" container spacing={1} margin={0} padding={1}>
                    <Grid xs={1} className="name">#</Grid>
                    <Grid xs={1.4}><div className="name">1ST</div></Grid>
                    <Grid xs={1.4}><div className="name">2ND</div></Grid>
                    <Grid xs={1.4}><div className="name">3RD</div></Grid>
                    <Grid xs={1.4}><div className="name">4TH</div></Grid>
                    <Grid xs={1.4}><div className="name">5TH</div></Grid>
                    <Grid xs={1}><div className="name">Lev</div></Grid>
                    <Grid xs={3}><div className="name">Date</div></Grid>
                </Grid>
                { isLoaded ?
                    list.map((item:any, index) =>
                        <Grid key={(index+1)} className="ll-odd-even" container spacing={1} margin={0} padding={1}>
                            <Grid xs={1} className="name">{(index+1)}.</Grid>
                            <Grid xs={7}>
                                <Grid className="ll-odd-even" container spacing={1} margin={0} padding={0}>
                                    <Grid xs={2.4} paddingTop={0} paddingBottom={0}><div className={item.pattern[0]}></div></Grid>
                                    <Grid xs={2.4} paddingTop={0} paddingBottom={0}><div className={item.pattern[1]}></div></Grid>
                                    <Grid xs={2.4} paddingTop={0} paddingBottom={0}><div className={item.pattern[2]}></div></Grid>
                                    <Grid xs={2.4} paddingTop={0} paddingBottom={0}><div className={item.pattern[3]}></div></Grid>
                                    <Grid xs={2.4} paddingTop={0} paddingBottom={0}><div className={item.pattern[4]}></div></Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={1}><div className={"level "+item.countLevel.level} >{item.countLevel.count} {item.countLevel.level}</div></Grid>
                            <Grid xs={3}><div className="name">12-03-2022</div></Grid>
                        </Grid>
                    ) : ''
                }
            </div>
        </div>
    )

}

export default Oddeven;