import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { ENV_URL } from "../../constant";

interface NumberCombo{
    one:any;
    two:any;
    three:any;
    four:any;
    five:any;
    patterns:Array<any>;
    difference:any;

}
interface NumberList {
    id:string;
    users_id:number;
    draw_date:any;
    week_level:any;
    cid_combo:NumberCombo;
}


const Lastdrawing = () => {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [drawing, setDrawing] = useState<NumberList>({id:'',users_id:0,draw_date:'',week_level:'',cid_combo:{one:null,two:null,three:null,four:null,five:null,patterns:[],difference:null}});
    // const [cid, setCid] = useState<Cid>();

    useEffect(() => {
        fetch(ENV_URL+"/latestdrawing/",{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
        })
          .then(res => res.json())
          .then(
            (result) => {
            // setCid(result.cid);
            setDrawing(result[0]);
            setIsLoaded(true);
    
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              setIsLoaded(true);
              setError(error);
            }
          )
      }, [])

    return (
        <div className="ll-box small">
            <header>LAST DRAWING
                <label className="left-margin">{drawing.draw_date.string}</label>
                <label className="ll-patterns left-margin">
                    {drawing.cid_combo.patterns.length > 0 ?
                        drawing.cid_combo.patterns.map((pat:any, id) =>
                            <span key={(id)}>{pat.value}, </span>
                        )
                    :''}
                </label>
            </header>
            {isLoaded ?
                <Grid container spacing={1} margin={0} padding={1}>
                    <Grid xs={2.3} className="number-box">
                        <Grid container spacing={0} margin={0} padding={0}>
                            <Grid xs={6} textAlign="left"><label>{drawing.cid_combo.one.countLevel}</label> </Grid>
                            <Grid xs={6} textAlign="right"><label>{drawing.cid_combo.difference.col_set_one}</label> </Grid>
                        </Grid>
                        <div className="outer-circle selected">
                            <div className={"inner-circle "+drawing.cid_combo.one.countLevel}><div className="inner-number">{drawing.cid_combo.one.value}</div></div>
                        </div>
                        <Grid container spacing={0} margin={0} padding={0}>
                            {/* <Grid xs={1}><div></div></Grid> */}
                            <Grid xs={12}><div className={"label-status bar "+drawing.cid_combo.one.oddEven}></div></Grid>
                            {/* <Grid xs={1}><div></div></Grid> */}
                        </Grid>
                    </Grid>

                    <Grid xs={2.3} className="number-box">
                        <Grid container spacing={0} margin={0} padding={0}>
                            <Grid xs={6} textAlign="left"><label>{drawing.cid_combo.two.countLevel}</label> </Grid>
                            <Grid xs={6} textAlign="right"><label>{drawing.cid_combo.difference.col_set_two}</label> </Grid>
                        </Grid>
                        <div className="outer-circle selected">
                            <div className={"inner-circle "+drawing.cid_combo.two.countLevel}><div className="inner-number">{drawing.cid_combo.two.value}</div></div>
                        </div>
                        <Grid container spacing={0} margin={0} padding={0}>
                            {/* <Grid xs={4}><div className={"label-status "+drawing.week_level.value_b}>W</div></Grid> */}
                            <Grid xs={12}><div className={"label-status bar "+drawing.cid_combo.two.oddEven}></div></Grid>
                            {/* <Grid xs={4}> <div className={"label-status "+drawing.cid_combo.two.countLevel}>C</div></Grid> */}
                        </Grid>
                    </Grid>

                    <Grid xs={2.3} className="number-box">
                        <Grid container spacing={0} margin={0} padding={0}>
                            <Grid xs={6} textAlign="left"><label>{drawing.cid_combo.three.countLevel}</label> </Grid>
                            <Grid xs={6} textAlign="right"><label>{drawing.cid_combo.difference.col_set_three}</label> </Grid>
                        </Grid>
                        <div className="outer-circle selected">
                            <div className={"inner-circle "+drawing.cid_combo.three.countLevel}><div className="inner-number">{drawing.cid_combo.three.value}</div></div>
                        </div>
                        <Grid container spacing={0} margin={0} padding={0}>
                            {/* <Grid xs={4}><div className={"label-status "+drawing.week_level.value_c}>W</div></Grid> */}
                            <Grid xs={12}><div className={"label-status bar "+drawing.cid_combo.three.oddEven}></div></Grid>
                            {/* <Grid xs={4}> <div className={"label-status "+drawing.cid_combo.three.countLevel}>C</div></Grid> */}
                        </Grid>
                    </Grid>

                    <Grid xs={2.3} className="number-box">
                        <Grid container spacing={0} margin={0} padding={0}>
                            <Grid xs={6} textAlign="left"><label>{drawing.cid_combo.four.countLevel}</label> </Grid>
                            <Grid xs={6} textAlign="right"><label>{drawing.cid_combo.difference.col_set_four}</label> </Grid>
                        </Grid>
                        <div className="outer-circle selected">
                            <div className={"inner-circle "+drawing.cid_combo.four.countLevel}><div className="inner-number">{drawing.cid_combo.four.value}</div></div>
                        </div>
                        <Grid container spacing={0} margin={0} padding={0}>
                            {/* <Grid xs={4}><div className={"label-status "+drawing.week_level.value_d}>W</div></Grid> */}
                            <Grid xs={12}><div className={"label-status bar "+drawing.cid_combo.four.oddEven}></div></Grid>
                            {/* <Grid xs={4}> <div className={"label-status "+drawing.cid_combo.four.countLevel}>C</div></Grid> */}
                        </Grid>
                    </Grid>

                    <Grid xs={2.3} className="number-box">
                        <Grid container spacing={0} margin={0} padding={0}>
                            <Grid xs={6} textAlign="left"><label>{drawing.cid_combo.five.countLevel}</label> </Grid>
                            <Grid xs={6} textAlign="right"><label>{drawing.cid_combo.difference.col_set_five}</label> </Grid>
                        </Grid>
                        <div className="outer-circle selected">
                            <div className={"inner-circle "+drawing.cid_combo.five.countLevel}><div className="inner-number">{drawing.cid_combo.five.value}</div></div>
                        </div>
                        <Grid container spacing={0} margin={0} padding={0}>
                            {/* <Grid xs={4}><div className={"label-status "+drawing.week_level.value_e}>W</div></Grid> */}
                            <Grid xs={12}><div className={"label-status bar "+drawing.cid_combo.five.oddEven}></div></Grid>
                            {/* <Grid xs={4}> <div className={"label-status "+drawing.cid_combo.five.countLevel}>C</div></Grid> */}
                        </Grid>
                    </Grid>
                    
                </Grid>
                : ''
            }
        </div>
    )

}

export default Lastdrawing;