import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { LinearProgress } from "@mui/material";
import { ENV_URL } from "../../constant";

interface Number {
    count: number;
    level: string;
  }

interface Combo {
    countLevel: Number;
    pattern: string;
}

interface Score {
    low: number;
    mid: number;
    high: number;
}



const Patterns = () => {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [patterns, setPatterns] = useState<Combo[]>([]);
    const [score, setScore] = useState<Score>({'low':0,'mid':0,'high':0});


    useEffect(() => {
        fetch(ENV_URL+"/patterncount/",{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
        })
          .then(res => res.json())
          .then(
            (result) => {
              setPatterns(result.combos);
              setScore(result.score);
              setIsLoaded(true);
    
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              setIsLoaded(true);
              setError(error);
            }
          )
      }, [])

    return (
    
        <div className="ll-box mid bottom-margin">
            <header>PATTERNS</header>
            <div className="section">
              {isLoaded ?
                  patterns.map((item:any, index) =>
                      <Grid key={(index+1)} className="ll-patterns" container spacing={0} margin={0} padding={1}>
                          <Grid xs={1} className="name">{item.pattern}</Grid>
                          <Grid xs={9} className="progress-bar"><LinearProgress variant="determinate" value={Math.round((item.countLevel.count / score.high) * 100)} className={item.countLevel.level } /></Grid>
                          <Grid xs={1} className="percent">{Math.round((item.countLevel.count / score.high) * 100)}%</Grid>
                          <Grid xs={1} className={"level "+item.countLevel.level}>{item.countLevel.level}</Grid>
                      </Grid>
                  ) : ''
              }
            </div>
        </div>

    )
}

export default Patterns;