import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { ENV_URL } from "../../constant";

interface Number {
    value:number
    count: number;
    level: string;
  }


const Numberlevel = () =>{

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [col1, setCol1] = useState<Number[]>([]);
    const [col2, setCol2] = useState<Number[]>([]);
    const [col3, setCol3] = useState<Number[]>([]);
    const [col4, setCol4] = useState<Number[]>([]);
    const [col5, setCol5] = useState<Number[]>([]);

    useEffect(() => {
        fetch(ENV_URL+"/numbercount/",{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
        })
          .then(res => res.json())
          .then(
            (result) => {
              setIsLoaded(true);
              setCol1(result[0].numbers);
              setCol2(result[1].numbers);
              setCol3(result[2].numbers);
              setCol4(result[3].numbers);
              setCol5(result[4].numbers);
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              setIsLoaded(true);
              setError(error);
            }
          )
      }, [])

    return(
        <div className="ll-box mid">
            <header>COLUMN LEVEL</header>
            <div className="section">
              <Grid  className="ll-column-levels" container spacing={1} margin={0} padding={1}>
                  <Grid xs={1} className="name">#</Grid>
                  <Grid xs={2.2}><div className="name">1ST</div></Grid>
                  <Grid xs={2.2}><div className="name">2ND</div></Grid>
                  <Grid xs={2.2}><div className="name">3RD</div></Grid>
                  <Grid xs={2.2}><div className="name">4TH</div></Grid>
                  <Grid xs={2.2}><div className="name">5TH</div></Grid>
              </Grid>
              { isLoaded ?
                  col1.map((item:any, index) =>
                      <Grid key={(item.value)} className="ll-column-levels" container spacing={1} margin={0} padding={1}>
                          <Grid xs={1} className="name">{(item.value)}.</Grid>
                          <Grid xs={2.2}><div className={"level "+item.level} >{item.count}{item.level}</div></Grid>
                          <Grid xs={2.2}><div className={"level "+col2[index].level} >{col2[index].count}{col2[index].level}</div></Grid>
                          <Grid xs={2.2}><div className={"level "+col3[index].level} >{col3[index].count}{col3[index].level}</div></Grid>
                          <Grid xs={2.2}><div className={"level "+col4[index].level} >{col4[index].count}{col4[index].level}</div></Grid>
                          <Grid xs={2.2}><div className={"level "+col5[index].level} >{col5[index].count}{col5[index].level}</div></Grid>
                      </Grid>
                  ) : ''
              }
          </div>
        </div>
    )

}

export default Numberlevel;