import React, { useEffect, useState } from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ENV_URL } from "../../constant";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

interface Combo {
    countLevel: Number;
    pattern: string;
}


const CreateNumbers = (props: any) => {

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [col1, setCol1] = useState<Number[]>([]);
    const [col2, setCol2] = useState<Number[]>([]);
    const [col3, setCol3] = useState<Number[]>([]);
    const [col4, setCol4] = useState<Number[]>([]);
    const [col5, setCol5] = useState<Number[]>([]);

    const [num1, setnum1] = React.useState<string>('');
    const [num2, setnum2] = React.useState<string>('');
    const [num3, setnum3] = React.useState<string>('');
    const [num4, setnum4] = React.useState<string>('');
    const [num5, setnum5] = React.useState<string>('');

    const [patterns, setPatterns] = useState<Combo[]>([]);
    const [selectedPat, setSelectedPat] = useState<string>('');

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const [checked, setChecked] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        fetch(ENV_URL+"/numbercount/",{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
        })
          .then(res => res.json())
          .then(
            (result) => {
              setCol1(result[0].numbers);
              setCol2(result[1].numbers);
              setCol3(result[2].numbers);
              setCol4(result[3].numbers);
              setCol5(result[4].numbers);
            //   setIsLoaded(true);
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              setIsLoaded(true);
              setError(error);
            }
          )

          fetch(ENV_URL+"/patterncount/",{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
        })
          .then(res => res.json())
          .then(
            (result) => {
              setPatterns(result.combos);
              setIsLoaded(true);
    
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              setIsLoaded(true);
              setError(error);
            }
          )


      }, [])


    const handleChange = (event: SelectChangeEvent,id:any) => {

        switch (id) {
            case 'col1':
                setnum1(event.target.value);
                break;
            case 'col2':
                setnum2(event.target.value);
                break;
            case 'col3':
                setnum3(event.target.value);
                break;
            case 'col4':
                setnum4(event.target.value);
                break;
            case 'col5':
                setnum5(event.target.value);
                break;
        
            default:
                break;
        }
    };

    const getUser = () => {
        //Check if User excist in Storage
        let userData:any = sessionStorage.getItem('user');
        return JSON.parse(userData);
    }

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const createNumbers = () =>{
        console.log('CREATE NUMBERS');
        if (num1 !== '' && num2 !== '' && num3 !== '' && num4 !== '' && num5 !== ''){
            //TODO: CHECK FOR UNDEFINED  selectedNum1
            let genObj = {
            value_a: num1,
            value_b: num2,
            value_c: num3,
            value_d: num4,
            value_e: num5
            }
            let user = getUser();
            // this.genirateNumbersService.setUserNumber(user,genObj).subscribe((results)=>{
            //   console.log(results);
            //   this.broadcastService.send('new-numbers', genObj);
            // });

            fetch(ENV_URL+"/addusernumber/"+user.id,{
                method: 'POST',
                headers: {
                'Content-Type': 'application/json'
                },
                body:JSON.stringify(genObj)
            })
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result);
                    setOpen(false);
                    props.updateStamp();
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                setIsLoaded(true);
                setError(error);
                }
            )
        }else{
            console.log('MISSING NUMBERS');
        }
    }

    const genirateNumbers = () => {

        if (selectedPat !== ''){

            let genObj = {
            patterns: selectedPat,
            maxnumbers: 1
            }
            let user = getUser();
            fetch(ENV_URL+"/gennumbers/"+user.id,{
                method: 'POST',
                headers: {
                'Content-Type': 'application/json'
                },
                body:JSON.stringify(genObj)
            })
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result);
                    setOpen(false);
                    props.updateStamp();
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                setIsLoaded(true);
                setError(error);
                }
            )
        }else{
            console.log('MISSING PATTERN');
        }

    }


    const handleSubmit = () =>{
        setOpen(true);
        if (checked){
            console.log('genirateNumbers');
            genirateNumbers();
        }else{
            console.log('createNumbers');
            createNumbers();
        }
    }

    return (
    
        <div className="ll-box small">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <header>CREATE NUMBERS</header>
            <div className="ll-number-select section">
                <FormControl variant="standard" sx={{ m: 1, minWidth: 40 }}>
                    <InputLabel id="col1-dp">Col1</InputLabel>
                    <Select
                    labelId="col1-select"
                    id="col1-select"
                    value={num1}
                    onChange={(e) => handleChange(e, 'col1')}
                    label="Col1"
                    >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    { isLoaded ?
                        col1.map((item:any, index) =>
                        <MenuItem  key={(item.value)}  value={item.value}>{(item.value)} {(item.level)}</MenuItem>
                        ) : ''
                    }
                    </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 40 }}>
                    <InputLabel id="col2-dp">Col2</InputLabel>
                    <Select
                    labelId="col2-select"
                    id="col2-select"
                    value={num2}
                    onChange={(e) => handleChange(e, 'col2')}
                    label="Col2"
                    >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    { isLoaded ?
                        col2.map((item:any, index) =>
                        <MenuItem key={(item.value)} value={item.value}>{(item.value)} {(item.level)}</MenuItem>
                        ) : ''
                    }
                    </Select>
                </FormControl>

                <FormControl variant="standard" sx={{ m: 1, minWidth: 40 }}>
                    <InputLabel id="col3-dp">Col3</InputLabel>
                    <Select
                    labelId="col3-select"
                    id="col3-select"
                    value={num3}
                    onChange={(e) => handleChange(e, 'col3')}
                    label="Col3"
                    >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    { isLoaded ?
                        col3.map((item:any, index) =>
                        <MenuItem key={(item.value)} value={item.value}>{(item.value)} {(item.level)}</MenuItem>
                        ) : ''
                    }
                    </Select>
                </FormControl>

                <FormControl variant="standard" sx={{ m: 1, minWidth: 40 }}>
                    <InputLabel id="col4-dp">Col4</InputLabel>
                    <Select
                    labelId="col4-select"
                    id="col4-select"
                    value={num4}
                    onChange={(e) => handleChange(e, 'col4')}
                    label="Col4"
                    >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    { isLoaded ?
                        col4.map((item:any, index) =>
                        <MenuItem key={(item.value)} value={item.value}>{(item.value)} {(item.level)}</MenuItem>
                        ) : ''
                    }
                    </Select>
                </FormControl>

                <FormControl variant="standard" sx={{ m: 1, minWidth: 40 }}>
                    <InputLabel id="col5-dp">Col5</InputLabel>
                    <Select
                    labelId="col5-select"
                    id="col5-select"
                    value={num5}
                    onChange={(e) => handleChange(e, 'col5')}
                    label="Col5"
                    >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    { isLoaded ?
                        col5.map((item:any, index) =>
                        <MenuItem key={(item.value)} value={item.value}>{(item.value)} {(item.level)}</MenuItem>
                        ) : ''
                    }
                    </Select>
                </FormControl>

                <div>
                <FormControlLabel control={<Checkbox checked={checked} onChange={handleCheckboxChange} sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }} />} label="GEN BY PAT" />
                </div>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 40 }}>
                    <InputLabel id="pat-dp">Patterns</InputLabel>
                    <Select
                    labelId="pat-select"
                    id="pat-select"
                    value={selectedPat}
                    onChange={(e) => setSelectedPat(e.target.value)}
                    label="pat"
                    >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    { isLoaded ?
                        patterns.map((item:any, index) =>
                        <MenuItem key={(index)} value={item.pattern}>{(item.pattern)} {(item.countLevel.level)}</MenuItem>
                        ) : ''
                    }
                    </Select>
                </FormControl>
                <div>
                <Button size="medium" onClick={handleSubmit}>ADD</Button>
                </div>

            </div>
    
        </div>
    )
}

export default CreateNumbers;