import React, { useState } from "react";
import NavBar from "../navbar/NavBar";
import Grid from '@mui/material/Unstable_Grid2';
import './Dashboard.scss';
import Lastdrawing from "../lastdrawing/Lastdrawing";
// import { LinearProgress } from "@mui/material";
import { ClassNames } from "@emotion/react";
import Patterns from "../paterns/Patrerns";
import Weekly from "../weekly/Weekly";
import Numberlevel from "../numberlevel/Numberlevel";
import Oddeven from "../oddeven/Oddeven";
import UserNumbers from "../usernumbers/Usernumbers";
import CreateNumbers from "../createnumbers/Createnumbers";
import LastSixty from "../lastsixty/Lastsixty";
import AdvaceCreate from "../advancecreate/advancecreate";


const Dashboard = () => {

    const [stamp, setUdpateStamp] = useState('');

    const updateStamp = () => {
        let timeStamp = new Date();
        setUdpateStamp(timeStamp.toTimeString());
     }

    return (
        <div className="ll-dashboard">
            <NavBar></NavBar>
            <section>
                <Grid container spacing={2} margin={0} padding={2} className="no-bottom-padding">
                    <Grid xs={12} md={6} lg={3} className="no-bottom-padding">
                        <div className="ll-box small">
                            <header>TODAYS JACKPOT</header>
                            <label className="ll-date left-margin">12.28.2022</label>
                            <div className="section">
                                <div className="ll-jackpot">$263,000.00</div>
                            </div>
                        </div>
                    </Grid>
                    <Grid xs={12} md={6} lg={3} className="no-bottom-padding">
                        <Lastdrawing></Lastdrawing>
                    </Grid>
                    <Grid xs={12} md={6} lg={3} className="no-bottom-padding">
                        <CreateNumbers updateStamp={updateStamp}></CreateNumbers>
                    </Grid>
                    <Grid xs={12} md={6} lg={3} className="no-bottom-padding">
                        <AdvaceCreate updateStamp={updateStamp} ></AdvaceCreate>
                    </Grid>
                </Grid>

                <Grid container spacing={2} margin={0} padding={2}>
                    <Grid xs={12} md={6} lg={3}>
                        <Patterns></Patterns>
                        <Numberlevel></Numberlevel>
                    </Grid>
                    <Grid xs={12} md={6} lg={3}>
                        <UserNumbers stamp={stamp}></UserNumbers>
                    </Grid>
                    <Grid xs={12} md={6} lg={3}>
                        <LastSixty></LastSixty>
                    </Grid>
                    <Grid xs={12} md={6} lg={3}>
                        <Oddeven></Oddeven>
                        <Weekly></Weekly>
                    </Grid>
                </Grid>
                
            </section>
        </div>
    );

}

export default Dashboard;