import React, { useEffect, useState } from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { ENV_URL } from "../../constant";

interface Combo {
    countLevel: Number;
    pattern: string;
}

const AdvaceCreate = (props: any) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    const [lmhCol1, setlmhCol1] = useState<string>('');
    const [lmhCol2, setlmhCol2] = useState<string>('');
    const [lmhCol3, setlmhCol3] = useState<string>('');
    const [lmhCol4, setlmhCol4] = useState<string>('');
    const [lmhCol5, setlmhCol5] = useState<string>('');

    const [oddEvenCol1, setOddEvenCol1] = useState<string>('');
    const [oddEvenCol2, setOddEvenCol2] = useState<string>('');
    const [oddEvenCol3, setOddEvenCol3] = useState<string>('');
    const [oddEvenCol4, setOddEvenCol4] = useState<string>('');
    const [oddEvenCol5, setOddEvenCol5] = useState<string>('');

    const [patterns, setPatterns] = useState<Combo[]>([]);
    const [selectedPat, setSelectedPat] = useState<string>('');

    const [anount, setAmount] = useState<string>('');
    const [selectLmhBox, setSelectLmhBox ] = useState<boolean>(false);
    const [selectOddEvenBox, setSelectOddEvenBox ] = useState<boolean>(false);
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        fetch(ENV_URL+"/patterncount/",{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
        })
          .then(res => res.json())
          .then(
            (result) => {
              setPatterns(result.combos);
              setIsLoaded(true);
    
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              setIsLoaded(true);
              setError(error);
            }
          )

    }, [])

    const getUser = () => {
        //Check if User excist in Storage
        let userData:any = sessionStorage.getItem('user');
        return JSON.parse(userData);
    }


    const genPatAdv = () =>{

        if (selectedPat !== ''){

            let genObj = {
                lmhCol1:lmhCol1,
                lmhCol2:lmhCol2,
                lmhCol3:lmhCol3,
                lmhCol4:lmhCol4,
                lmhCol5:lmhCol5,
                oddEvenCol1:oddEvenCol1,
                oddEvenCol2:oddEvenCol2,
                oddEvenCol3:oddEvenCol3,
                oddEvenCol4:oddEvenCol4,
                oddEvenCol5:oddEvenCol5,
                patterns: selectedPat,
                maxnumbers: anount
            }
            console.log(genObj)
            let user = getUser();
            fetch(ENV_URL+"/genPatAdv/"+user.id,{
                method: 'POST',
                headers: {
                'Content-Type': 'application/json'
                },
                body:JSON.stringify(genObj)
            })
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result);
                    setOpen(false);
                    props.updateStamp();
                },
                (error) => {
                setIsLoaded(true);
                setError(error);
                }
            )
        }else{
            console.log('MISSING PATTERN');
        }
    }


    const genPatCountLevel = () =>{

        if (selectedPat !== ''){

            let genObj = {
                lmhCol1:lmhCol1,
                lmhCol2:lmhCol2,
                lmhCol3:lmhCol3,
                lmhCol4:lmhCol4,
                lmhCol5:lmhCol5,
                patterns: selectedPat,
                maxnumbers: anount
            }
            console.log(genObj)
            let user = getUser();
            fetch(ENV_URL+"/genPatCountLevel/"+user.id,{
                method: 'POST',
                headers: {
                'Content-Type': 'application/json'
                },
                body:JSON.stringify(genObj)
            })
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result);
                    setOpen(false);
                    props.updateStamp();
                },
                (error) => {
                setIsLoaded(true);
                setError(error);
                }
            )
        }else{
            console.log('MISSING PATTERN');
        }
    }

    const genPatOddEven = () =>{

        if (selectedPat !== ''){

            let genObj = {
                oddEvenCol1:oddEvenCol1,
                oddEvenCol2:oddEvenCol2,
                oddEvenCol3:oddEvenCol3,
                oddEvenCol4:oddEvenCol4,
                oddEvenCol5:oddEvenCol5,
                patterns: selectedPat,
                maxnumbers: anount
            }
            console.log(genObj)
            let user = getUser();
            fetch(ENV_URL+"/genPatOddEven/"+user.id,{
                method: 'POST',
                headers: {
                'Content-Type': 'application/json'
                },
                body:JSON.stringify(genObj)
            })
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result);
                    setOpen(false);
                    props.updateStamp();
                },
                (error) => {
                setIsLoaded(true);
                setError(error);
                }
            )
        }else{
            console.log('MISSING PATTERN');
        }
    }

    const handleSubmit = () =>{
        setOpen(true);
        if (selectedPat !== '' && anount !== ''){
            if(selectLmhBox && selectOddEvenBox ){
                genPatAdv();
            }else if (selectLmhBox){
                genPatCountLevel();
            }else if (selectOddEvenBox){
                genPatOddEven();
            }else{
                setOpen(false);
                alert('Select Low, Mid, High or Odd Even');
            }
        }else{
            setOpen(false);
            alert('Must Selected Pattern & Amount');
        }
    
    }

    const handleLmhCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectLmhBox(event.target.checked);
    };

    const handleOddEvenCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectOddEvenBox(event.target.checked);
    };

    return (
    
        <div className="ll-box small">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <header>ADVANCE CREATE</header>
            {/* <label className="left-margin">Low, Mid, High</label> */}


            {/* <FormControlLabel control={<Checkbox checked={selectLmhBox} onChange={handleLmhCheckboxChange} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />} label="Low, Mid, High" /> */}
            <div className="section">
            <FormControlLabel control={<Checkbox checked={selectLmhBox} onChange={handleLmhCheckboxChange} sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }} />} label="Low, Mid, High" />
                <div className="ll-number-select">
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 40 }}>
                        <InputLabel id="col1-dp">Col1</InputLabel>
                        <Select
                        labelId="col1-select"
                        id="col1-select"
                        value={lmhCol1}
                        onChange={(e) => setlmhCol1(e.target.value)}
                        label="Col1"
                        >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value="L">Low</MenuItem>
                        <MenuItem value="M">Mid</MenuItem>
                        <MenuItem value="H">High</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 40 }}>
                        <InputLabel id="col2-dp">Col2</InputLabel>
                        <Select
                        labelId="col2-select"
                        id="col2-select"
                        value={lmhCol2}
                        onChange={(e) => setlmhCol2(e.target.value)}
                        label="Col2"
                        >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value="L">Low</MenuItem>
                        <MenuItem value="M">Mid</MenuItem>
                        <MenuItem value="H">High</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 40 }}>
                        <InputLabel id="col3-dp">Col3</InputLabel>
                        <Select
                        labelId="col3-select"
                        id="col3-select"
                        value={lmhCol3}
                        onChange={(e) => setlmhCol3(e.target.value)}
                        label="Col3"
                        >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value="L">Low</MenuItem>
                        <MenuItem value="M">Mid</MenuItem>
                        <MenuItem value="H">High</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 40 }}>
                        <InputLabel id="col4-dp">Col4</InputLabel>
                        <Select
                        labelId="col3-select"
                        id="col3-select"
                        value={lmhCol4}
                        onChange={(e) => setlmhCol4(e.target.value)}
                        label="Col4"
                        >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value="L">Low</MenuItem>
                        <MenuItem value="M">Mid</MenuItem>
                        <MenuItem value="H">High</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 40 }}>
                        <InputLabel id="col5-dp">Col5</InputLabel>
                        <Select
                        labelId="col5-select"
                        id="col5-select"
                        value={lmhCol5}
                        onChange={(e) => setlmhCol5(e.target.value)}
                        label="Col5"
                        >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value="L">Low</MenuItem>
                        <MenuItem value="M">Mid</MenuItem>
                        <MenuItem value="H">High</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                {/* <label className="left-margin">Odd, Even Pattern</label> */}
                <FormControlLabel control={<Checkbox checked={selectOddEvenBox} onChange={handleOddEvenCheckboxChange} sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }} />} label="Odd, Even Pattern" />
                <div className="ll-number-select">
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 40 }}>
                        <InputLabel id="col1-dp">Col1</InputLabel>
                        <Select
                        labelId="col1-select"
                        id="col1-select"
                        value={oddEvenCol1}
                        onChange={(e) => setOddEvenCol1(e.target.value)}
                        label="Col1"
                        >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value="Odd">Odd</MenuItem>
                        <MenuItem value="Even">Even</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 40 }}>
                        <InputLabel id="col2-dp">Col2</InputLabel>
                        <Select
                        labelId="col2-select"
                        id="col2-select"
                        value={oddEvenCol2}
                        onChange={(e) => setOddEvenCol2(e.target.value)}
                        label="Col2"
                        >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value="Odd">Odd</MenuItem>
                        <MenuItem value="Even">Even</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 40 }}>
                        <InputLabel id="col3-dp">Col3</InputLabel>
                        <Select
                        labelId="col3-select"
                        id="col3-select"
                        value={oddEvenCol3}
                        onChange={(e) => setOddEvenCol3(e.target.value)}
                        label="Col3"
                        >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value="Odd">Odd</MenuItem>
                        <MenuItem value="Even">Even</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 40 }}>
                        <InputLabel id="col4-dp">Col4</InputLabel>
                        <Select
                        labelId="col3-select"
                        id="col3-select"
                        value={oddEvenCol4}
                        onChange={(e) => setOddEvenCol4(e.target.value)}
                        label="Col4"
                        >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value="Odd">Odd</MenuItem>
                        <MenuItem value="Even">Even</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 40 }}>
                        <InputLabel id="col5-dp">Col5</InputLabel>
                        <Select
                        labelId="col5-select"
                        id="col5-select"
                        value={oddEvenCol5}
                        onChange={(e) => setOddEvenCol5(e.target.value)}
                        label="Col5"
                        >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value="Odd">Odd</MenuItem>
                        <MenuItem value="Even">Even</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                {/* <label className="left-margin">Pattern</label> */}

                <div className="ll-number-select">
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 40 }}>
                        <InputLabel id="pat-dp">Patterns</InputLabel>
                        <Select
                        labelId="pat-select"
                        id="pat-select"
                        value={selectedPat}
                        onChange={(e) => setSelectedPat(e.target.value)}
                        label="pat"
                        >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        { isLoaded ?
                            patterns.map((item:any, index) =>
                            <MenuItem key={(index)} value={item.pattern}>{(item.pattern)} {(item.countLevel.level)}</MenuItem>
                            ) : ''
                        }
                        </Select>
                    </FormControl>
                </div> 
                <div className="ll-number-select">
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 40 }}>
                        <InputLabel id="pat-dp">Amount</InputLabel>
                        <Select
                        labelId="pat-select"
                        id="pat-select"
                        value={anount}
                        onChange={(e) => setAmount(e.target.value)}
                        label="pat"
                        >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value="1">1</MenuItem>
                        <MenuItem value="5">5</MenuItem>
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="20">20</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className="ll-number-select">
                    <Button size="medium" onClick={handleSubmit}>ADD</Button>
                </div>

            </div>
    
        </div>
    )
}

export default AdvaceCreate;