import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { ENV_URL } from "../../constant";

interface Number {
    value: number;
    count: number;
    level: string;
  }

const Weekly = () =>{

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [sun, setSun] = useState<Number[]>([]);
    const [mon, setMon] = useState<Number[]>([]);
    const [tue, setTue] = useState<Number[]>([]);
    const [wed, setWed] = useState<Number[]>([]);
    const [thu, setThu] = useState<Number[]>([]);
    const [fri, setFri] = useState<Number[]>([]);
    const [sat, setSat] = useState<Number[]>([]);
    let todayDate = new Date();
    const dayNum = todayDate.getDay();

    useEffect(() => {
        fetch(ENV_URL+"/weeklycount/",{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
        })
          .then(res => res.json())
          .then(
            (result) => {
              setIsLoaded(true);
              setSun(result[0].numbers);
              setMon(result[1].numbers);
              setTue(result[2].numbers);
              setWed(result[3].numbers);
              setThu(result[4].numbers);
              setFri(result[5].numbers);
              setSat(result[6].numbers);
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              setIsLoaded(true);
              setError(error);
            }
          )
      }, [])

    const checkDate = (day:number):any => {
       return (day === 1)?"today":""
    }

    return(
        <div className="ll-box mid">
          <header>WEEKLY {todayDate.getDay()}</header>
          <div className="section">
            <Grid className="ll-weekly" container spacing={1} margin={0} padding={1}>
                <Grid xs={1} className="name">#</Grid>
                <Grid xs={1.4}><div className={"name " + checkDate(0)}>SUN.</div></Grid>
                <Grid xs={1.4}><div className={"name " + checkDate(1)}>MON.</div></Grid>
                <Grid xs={1.4}><div className={"name " + checkDate(2)}>TUE.</div></Grid>
                <Grid xs={1.4}><div className={"name " + checkDate(3)}>WED.</div></Grid>
                <Grid xs={1.4}><div className={"name " + checkDate(4)}>THU.</div></Grid>
                <Grid xs={1.4}><div className={"name " + checkDate(5)}>FRI.</div></Grid>
                <Grid xs={1.4}><div className={"name " + checkDate(6)}>SAT.</div></Grid>
                <Grid xs={1} className="name">Lev</Grid>
            </Grid>

            {isLoaded ?
                sun.map((item:any, index) =>
                    <Grid key={item.value} className="ll-weekly" container spacing={1} margin={0} padding={1}>
                        <Grid xs={1} className="name">{item.value}</Grid>
                        <Grid xs={1.4}><div className={"level-box "}>{item.count}</div></Grid>
                        <Grid xs={1.4}><div className={"level-box "}>{mon[index].count}</div></Grid>
                        <Grid xs={1.4}><div className={"level-box "}>{tue[index].count}</div></Grid>
                        <Grid xs={1.4}><div className={"level-box "}>{wed[index].count}</div></Grid>
                        <Grid xs={1.4}><div className={"level-box "}>{thu[index].count}</div></Grid>
                        <Grid xs={1.4}><div className={"level-box "}>{fri[index].count}</div></Grid>
                        <Grid xs={1.4}><div className={"level-box "}>{sat[index].count}</div></Grid>
                        <Grid xs={1} className="name">{item.score.high}</Grid>
                    </Grid>
                ) : ''
            }
          </div>
        </div>
    )

}

export default Weekly;