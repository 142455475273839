import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Unstable_Grid2';
import { format } from 'date-fns';
import { ENV_URL } from "../../constant";

interface NumberCombo{
    one:any;
    two:any;
    three:any;
    four:any;
    five:any;
    patterns:Array<any>;
    difference:any;
    has_played?:string;

}
interface NumberList {
    id:string;
    users_id:number;
    createdAt:any;
    cid_combo:NumberCombo;
    draw_date?:any;
    week_level?:any;
}

const LastSixty = () => {

    const [error, setError] = useState(null);
    // const [isLoaded, setIsLoaded] = useState(false);
    const [numbersList, setNumbersList] = useState<NumberList[]>([]);
    const [drawing, setDrawing] = useState<NumberList>({id:'',users_id:0,createdAt:'',draw_date:'',week_level:'',cid_combo:{one:null,two:null,three:null,four:null,five:null,patterns:[],difference:null}});
    const [numListLoaded, setNumListLoaded] = useState(false);
    const [drawingLoaded, setDrawingLoaded] = useState(false);

    useEffect(() => {
        fetch(ENV_URL+"/lastsixty/",{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
        })
          .then(res => res.json())
          .then(
            (result) => {
            setNumbersList(result);
            setNumListLoaded(true);
    
            },
            (error) => {
              setError(error);
            }
          )

          fetch(ENV_URL+"/latestdrawing/",{
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
        })
          .then(res => res.json())
          .then(
            (result) => {
            setDrawing(result[0]);
            setDrawingLoaded(true);
    
            },
            (error) => {
              setError(error);
            }
          )

      }, [])

    const checkIfMatched = (value:number):string => {
        return (value === drawing.cid_combo.one.value || value === drawing.cid_combo.two.value || value === drawing.cid_combo.three.value || value === drawing.cid_combo.four.value || value === drawing.cid_combo.five.value )? 'selected' : '';

    }

    return (
        <div className="ll-box large">
            <header>LAST SIXTY DAYS</header>
            <div className="section">
                {numListLoaded && drawingLoaded ?
                    numbersList.map((item:NumberList, index) =>
                    <div key={(index)} >
                        <div>
                            <label className="left-margin">{format(new Date(item.draw_date.string), 'yyyy-MM-dd')}</label>
                            <label className="ll-patterns left-margin">
                                {item.cid_combo.patterns.length > 0 ?
                                    item.cid_combo.patterns.map((pat:any, id) =>
                                        <span key={(id)}>{pat.value}, </span>
                                    )
                                :''}
                            </label>
                            <label className="ll-has-played left-margin">
                                Has Played: <span>{item.cid_combo.has_played === 'true' ? 'Yes': 'No'}</span>
                            </label>
                        </div>
                        <Grid container spacing={1} margin={0} padding={2}>
                            <Grid xs={2.3} className="number-box">
                                <Grid container spacing={0} margin={0} padding={0}>
                                    <Grid xs={6} textAlign="left"><label>{item.cid_combo.one.countLevel}</label> </Grid>
                                    <Grid xs={6} textAlign="right"><label>{item.cid_combo.difference.col_set_one}</label> </Grid>
                                </Grid>
                                <div className={"outer-circle "+ checkIfMatched(item.cid_combo.one.value)}   >
                                    <div className={"inner-circle "+item.cid_combo.one.countLevel}><div className="inner-number">{item.cid_combo.one.value}</div></div>
                                </div>
                                <Grid container spacing={0} margin={0} padding={0}>
                                    {/* <Grid xs={4}><div className={"label-status "+cid?.atributes[0].weekLevel}>W</div></Grid> */}
                                    <Grid xs={12}><div className={"label-status bar "+item.cid_combo.one.oddEven}></div></Grid>
                                    {/* <Grid xs={4}> <div className={"label-status "+item.cid_combo.one.countLevel}>C</div></Grid> */}
                                </Grid>
                            </Grid>

                            <Grid xs={2.3} className="number-box">
                                <Grid container spacing={0} margin={0} padding={0}>
                                    <Grid xs={6} textAlign="left"><label>{item.cid_combo.two.countLevel}</label> </Grid>
                                    <Grid xs={6} textAlign="right"><label>{item.cid_combo.difference.col_set_two}</label> </Grid>
                                </Grid>
                                <div className={"outer-circle "+ checkIfMatched(item.cid_combo.two.value)} >
                                    <div className={"inner-circle "+item.cid_combo.two.countLevel}><div className="inner-number">{item.cid_combo.two.value}</div></div>
                                </div>
                                <Grid container spacing={0} margin={0} padding={0}>
                                    {/* <Grid xs={4}><div className={"label-status "+cid?.atributes[0].weekLevel}>W</div></Grid> */}
                                    <Grid xs={12}><div className={"label-status bar "+item.cid_combo.two.oddEven}></div></Grid>
                                    {/* <Grid xs={4}> <div className={"label-status "+item.cid_combo.two.countLevel}>C</div></Grid> */}
                                </Grid>
                            </Grid>

                            <Grid xs={2.3} className="number-box">
                                <Grid container spacing={0} margin={0} padding={0}>
                                    <Grid xs={6} textAlign="left"><label>{item.cid_combo.three.countLevel}</label> </Grid>
                                    <Grid xs={6} textAlign="right"><label>{item.cid_combo.difference.col_set_three}</label> </Grid>
                                </Grid>
                                <div className={"outer-circle "+ checkIfMatched(item.cid_combo.three.value)} >
                                    <div className={"inner-circle "+item.cid_combo.three.countLevel}><div className="inner-number">{item.cid_combo.three.value}</div></div>
                                </div>
                                <Grid container spacing={0} margin={0} padding={0}>
                                    {/* <Grid xs={4}><div className={"label-status "+cid?.atributes[0].weekLevel}>W</div></Grid> */}
                                    <Grid xs={12}><div className={"label-status bar "+item.cid_combo.three.oddEven}></div></Grid>
                                    {/* <Grid xs={4}> <div className={"label-status "+item.cid_combo.three.countLevel}>C</div></Grid> */}
                                </Grid>
                            </Grid>

                            <Grid xs={2.3} className="number-box">
                                <Grid container spacing={0} margin={0} padding={0}>
                                    <Grid xs={6} textAlign="left"><label>{item.cid_combo.four.countLevel}</label> </Grid>
                                    <Grid xs={6} textAlign="right"><label>{item.cid_combo.difference.col_set_four}</label> </Grid>
                                </Grid>
                                <div className={"outer-circle "+ checkIfMatched(item.cid_combo.four.value)} >
                                    <div className={"inner-circle "+item.cid_combo.four.countLevel}><div className="inner-number">{item.cid_combo.four.value}</div></div>
                                </div>
                                <Grid container spacing={0} margin={0} padding={0}>
                                    {/* <Grid xs={4}><div className={"label-status "+cid?.atributes[0].weekLevel}>W</div></Grid> */}
                                    <Grid xs={12}><div className={"label-status bar "+item.cid_combo.four.oddEven}></div></Grid>
                                    {/* <Grid xs={4}> <div className={"label-status "+item.cid_combo.four.countLevel}>C</div></Grid> */}
                                </Grid>
                            </Grid>

                            <Grid xs={2.3} className="number-box">
                                <Grid container spacing={0} margin={0} padding={0}>
                                    <Grid xs={6} textAlign="left"><label>{item.cid_combo.five.countLevel}</label> </Grid>
                                    <Grid xs={6} textAlign="right"><label>{item.cid_combo.difference.col_set_five}</label> </Grid>
                                </Grid>
                                <div className={"outer-circle "+ checkIfMatched(item.cid_combo.five.value)} >
                                    <div className={"inner-circle "+item.cid_combo.five.countLevel}><div className="inner-number">{item.cid_combo.five.value}</div></div>
                                </div>
                                <Grid container spacing={0} margin={0} padding={0}>
                                    {/* <Grid xs={4}><div className={"label-status "+cid?.atributes[0].weekLevel}>W</div></Grid> */}
                                    <Grid xs={12}><div className={"label-status bar "+item.cid_combo.five.oddEven}></div></Grid>
                                    {/* <Grid xs={4}> <div className={"label-status "+item.cid_combo.five.countLevel}>C</div></Grid> */}
                                </Grid>
                            </Grid>

                            
                            
                        </Grid>
                    </div>
                ) : ''
                }
            </div>
        </div>
    )

}

export default LastSixty;